import React, { useState, useEffect } from 'react';
import { Search, Calendar, Users } from 'lucide-react';
import api from '../services/regiojet-api';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

// Регистрируем русскую локаль
registerLocale('ru', ru);

const SearchForm = ({ onSearch, t }) => {
  // Состояния
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Форма
  const [formData, setFormData] = useState({
    fromLocationId: '',
    toLocationId: '',
    departureDate: new Date().toISOString().split('T')[0],
    passengers: 1,
    tariffs: ['REGULAR']
  });

  // Загрузка локаций при монтировании
  useEffect(() => {
    const loadLocations = async () => {
      try {
        setLoading(true);
        setError(null);
        const response = await api.getLocations();
        
        // Сортируем локации по имени
        const sortedLocations = response.sort((a, b) => 
          a.name.localeCompare(b.name)
        );
        
        setLocations(sortedLocations);
      } catch (err) {
        console.error('Error loading locations:', err);
        setError(t.errorLoadingLocations || 'Error loading locations');
      } finally {
        setLoading(false);
      }
    };

    loadLocations();
  }, [t]);

  // Обработчик изменения формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      // Проверяем обязательные поля
      if (!formData.fromLocationId || !formData.toLocationId || !formData.departureDate) {
        throw new Error(t.pleaseSelectAllFields || 'Please select all required fields');
      }

      // Проверяем валидность даты
      const selectedDate = new Date(formData.departureDate);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (selectedDate < today) {
        throw new Error(t.invalidDate || 'Please select a future date');
      }

      await onSearch({
        fromLocationId: Number(formData.fromLocationId),
        toLocationId: Number(formData.toLocationId),
        departureDate: formData.departureDate,
        passengers: Number(formData.passengers),
        tariffs: formData.tariffs
      });
    } catch (err) {
      console.error('Search form error:', err);
      setError(err.message);
    }
  };

  // Получение даты для минимального значения
  const getMinDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  return (
    <div className="w-full max-w-2xl mx-auto">
     <div className="relative h-64 mb-8 rounded-lg overflow-hidden">
      <img
        src="/travel-background.png"
        alt="Travel"
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col justify-center items-center text-white p-6">
        <h1 className="text-4xl font-bold mb-2">{t.bannerTitle}</h1>
        <p className="text-xl">{t.bannerSubtitle}</p>
      </div>
    </div>
      <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
        {/* Заголовок */}
        <h2 className="text-2xl font-semibold mb-4">
          {t.searchRoutes}
        </h2>

        {/* Поле "Откуда" */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder={t.from || 'From'}
            className="w-full p-4 pl-12 rounded-lg border focus:outline-none focus:border-blue-500"
            name="fromLocationId"
            value={formData.fromLocationId}
            onChange={handleInputChange}
            required
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <Search size={20} className="text-gray-400" />
          </div>
        </div>

        {/* Поле "Куда" */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder={t.to || 'To'}
            className="w-full p-4 pl-12 rounded-lg border focus:outline-none focus:border-blue-500"
            name="toLocationId"
            value={formData.toLocationId}
            onChange={handleInputChange}
            required
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <Search size={20} className="text-gray-400" />
          </div>
        </div>

        {/* Дата отправления */}
        <div className="relative mb-4">
          <DatePicker
            selected={new Date(formData.departureDate)}
            onChange={(date) => setFormData(prev => ({ ...prev, departureDate: date.toISOString().split('T')[0] }))}
            dateFormat="dd MMM yyyy"
            placeholderText={t.departureDate || 'Departure Date'}
            className="w-full p-4 pl-12 rounded-lg border focus:outline-none focus:border-blue-500"
            minDate={new Date()}
            required
            locale="ru"
            monthNames={[
              'Январь', 'Февраль', 'Март', 'Апрель',
              'Май', 'Июнь', 'Июль', 'Август',
              'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
            ]}
            dayNames={[
              'Воскресенье', 'Понедельник', 'Вторник', 'Среда',
              'Четверг', 'Пятница', 'Суббота'
            ]}
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2 pointer-events-none">
            <Calendar size={20} className="text-gray-400" />
          </div>
        </div>

        {/* Количество пассажиров */}
        <div className="relative mb-4">
          <input
            type="text"
            placeholder={t.passengers || 'Passengers'}
            className="w-full p-4 pl-12 rounded-lg border focus:outline-none focus:border-blue-500"
            name="passengers"
            value={formData.passengers}
            onChange={handleInputChange}
          />
          <div className="absolute left-4 top-1/2 transform -translate-y-1/2">
            <Users size={20} className="text-gray-400" />
          </div>
        </div>

        {/* Отображение ошибки */}
        {error && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        {/* Состояние загрузки */}
        {loading ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500 mx-auto"></div>
            <p className="mt-2 text-gray-600">{t.loadingLocations || 'Loading locations...'}</p>
          </div>
        ) : (
          /* Кнопка поиска */
          <button
            type="submit"
            className="w-full bg-blue-500 hover:bg-blue-600 text-white py-3 px-6 rounded-lg transition-colors duration-200 flex items-center justify-center space-x-2"
            disabled={loading}
          >
            <Search size={20} />
            <span>{t.search || 'Search Routes'}</span>
          </button>
        )}
      </form>
    </div>
  );
};

export default SearchForm;