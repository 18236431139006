// src/utils/translations.js

export const LANGUAGES = {
  EN: 'en',
  RU: 'ru'
};

export const TRANSLATIONS = {
  [LANGUAGES.EN]: {
    // Общие
    title: "Travel that moves you",
    subtitle: "Book train, bus, flight and ferry tickets",
    error: "Error",
    close: "Close",
    loading: "Loading...",
    retry: "Retry",
    
    // Поиск маршрутов
    from: "From",
    to: "To",
    date: "Date",
    search: "Search",
    searchRoutes: "Search routes",
    enterCity: "Enter city name",
    fillAllFields: "Please fill all fields",
    invalidLocations: "Invalid locations selected",
    loadingLocations: "Loading locations...",
    
    // Пассажиры
    passengers: "passenger",
    passengers_plural: "passengers",
    adult: "adult",
    adults: "adults",
    
    // Маршруты
    popular: "Popular destinations",
    availableRoutes: "Available Routes",
    routeDetails: "Route Details",
    noRoutesFound: "No routes found",
    
    // Время и даты
    departure: "Departure",
    arrival: "Arrival",
    departureTime: "Departure Time", 
    arrivalTime: "Arrival Time",
    duration: "Duration",
    
    // Места
    availableSeats: "Available Seats",
    seatsAvailable: "seats available",
    selectedSeat: "Selected Seat",
    seatNumber: "Seat Number",
    selectSeat: "Select a Seat",
    noSeatsAvailable: "No seats available",
    errorFetchingSeats: "Error fetching available seats",
    
    // Бронирование
    book: "Book",
    bookingForm: "Booking Form",
    bookingStep: "Booking Step",
    bookingConfirmed: "Booking Confirmed!",
    bookingHistory: "Booking History",
    bookingCancelled: "Booking Cancelled",
    cancelBooking: "Cancel Booking",
    cancelBookingError: "Error cancelling booking",
    
    // Детали пассажира
    firstName: "First Name",
    lastName: "Last Name", 
    email: "Email",
    phone: "Phone",
    dateOfBirth: "Date of Birth",
    passengerDetails: "Passenger Details",
    
    // Билет
    ticketId: "Ticket ID",
    price: "Price",
    currency: "CZK",
    
    // Транспорт
    transportType: "Transport Type",
    vehicle: "Vehicle",
    train: "Train",
    bus: "Bus",
    plane: "Plane",
    ferry: "Ferry",
    
    // Навигация
    backToSearch: "Back to search",
    backToResults: "Back to results",
    
    // Фильтры и сортировка
    filterAndSort: "Filter and Sort",
    priceRange: "Price Range",
    min: "Min",
    max: "Max",
    sortBy: "Sort By",
    sortOrder: "Sort Order",
    ascending: "Ascending",
    descending: "Descending",
    apply: "Apply",
    
    // Оплата
    loadingPayment: "Loading payment form...",
    processing: "Processing...",
    payNow: "Pay Now",
    
    // Валидация
    phoneFormat: "Format: +420XXXXXXXXX",
    invalidPhone: "Please enter valid phone number in format +420XXXXXXXXX",
    firstNameRequired: "First name is required",
    lastNameRequired: "Last name is required", 
    invalidEmail: "Please enter valid email address",
    invalidDateOfBirth: "Please enter valid date of birth",
    requiredFields: "Required fields",
    
    // Ошибки
    errorLoadingData: "Error loading data",
    errorFetchingHistory: "Error fetching booking history",
    routeDetailsError: "Error loading route details",
    searchError: "Error performing search",
    filterError: "Error applying filters", 

    cardDetails: "Card Details",
    processingPayment: "Processing payment...",
    authenticating: "Authenticating...",
    paymentSuccess: "Payment successful",
  },

  [LANGUAGES.RU]: {
    // Общие
    title: "Путешествие, которое вдохновляет",
    subtitle: "Бронирование билетов на поезд, автобус, самолет и паром",
    error: "Ошибка",
    close: "Закрыть",
    loading: "Загрузка...",
    retry: "Повторить",
    
    // Поиск маршрутов
    from: "Откуда",
    to: "Куда",
    date: "Дата",
    search: "Поиск",
    searchRoutes: "Поиск маршрутов",
    enterCity: "Введите название города",
    fillAllFields: "Пожалуйста, заполните все поля",
    invalidLocations: "Выбраны неверные локации",
    loadingLocations: "Загрузка локаций...",
    
    // Пассажиры
    passengers: "пассажир",
    passengers_plural: "пассажиров",
    adult: "взрослый",
    adults: "взрослых",
    
    // Маршруты
    popular: "Популярные направления",
    availableRoutes: "Доступные маршруты",
    routeDetails: "Детали маршрута",
    noRoutesFound: "Маршруты не найдены",
    
    // Время и даты
    departure: "Отправление",
    arrival: "Прибытие",
    departureTime: "Время отправления",
    arrivalTime: "Время прибытия",
    duration: "Длительность",
    
    // Места
    availableSeats: "Доступные места",
    seatsAvailable: "мест доступно",
    selectedSeat: "Выбранное место",
    seatNumber: "Номер места",
    selectSeat: "Выберите место",
    noSeatsAvailable: "Нет доступных мест",
    errorFetchingSeats: "Ошибка при загрузке доступных мест",
    
    // Бронирование
    book: "Забронировать",
    bookingForm: "Форма бронирования",
    bookingStep: "Шаг бронирования",
    bookingConfirmed: "Бронирование подтверждено!",
    bookingHistory: "История бронирований",
    bookingCancelled: "Бронирование отменено",
    cancelBooking: "Отменить бронирование",
    cancelBookingError: "Ошибка при отмене бронирования",
    
    // Детали пассажира
    firstName: "Имя",
    lastName: "Фамилия",
    email: "Электронная почта",
    phone: "Телефон",
    dateOfBirth: "Дата рождения",
    passengerDetails: "Данные пассажира",
    
    // Билет
    ticketId: "Номер билета",
    price: "Цена",
    currency: "CZK",
    
    // Транспорт
    transportType: "Тип транспорта",
    vehicle: "Транспорт",
    train: "Поезд",
    bus: "Автобус",
    plane: "Самолет",
    ferry: "Паром",
    
    // Навигация
    backToSearch: "Вернуться к поиску",
    backToResults: "Вернуться к результатам",
    
    // Фильтры и сортировка
    filterAndSort: "Фильтры и сортировка",
    priceRange: "Диапазон цен",
    min: "Мин",
    max: "Макс",
    sortBy: "Сортировать по",
    sortOrder: "Порядок сортировки",
    ascending: "По возрастанию",
    descending: "По убыванию",
    apply: "Применить",
    
    // Оплата
    loadingPayment: "Загрузка формы оплаты...",
    processing: "Обработка...",
    payNow: "Оплатить",
    
    // Валидация
    phoneFormat: "Формат: +420XXXXXXXXX",
    invalidPhone: "Пожалуйста, введите корректный номер телефона в формате +420XXXXXXXXX",
    firstNameRequired: "Необходимо указать имя",
    lastNameRequired: "Необходимо указать фамилию",
    invalidEmail: "Пожалуйста, введите корректный email адрес",
    invalidDateOfBirth: "Пожалуйста, введите корректную дату рождения",
    requiredFields: "Обязательные поля",
    
    // Ошибки
    errorLoadingData: "Ошибка загрузки данных",
    errorFetchingHistory: "Ошибка при загрузке истории бронирований",
    routeDetailsError: "Ошибка загрузки деталей маршрута",
    searchError: "Ошибка при выполнении поиска",
    filterError: "Ошибка при применении фильтров",

    // Дополнительные переводы
    popularDestinations: "Популярные направления",
    chooseDestination: "Выберите между поездами, автобусами, самолетами и паромами",
    addReturn: "Добавить обратный",
    removeReturn: "Убрать обратный",
    findAccommodation: "Найти жилье",
    transfers: "Пересадки",
    delay: "Задержка",
    class: "Класс",
    availableServices: "Доступные услуги",
    confirm: "Подтвердить",
    bookingSummary: "Сводка бронирования",
    route: "Маршрут",
    printTicket: "Распечатать билет",
    instantPayment: "Мгновенная оплата через RegioJet Pay",
    ticketReady: "Ваш билет готов",
    viewTicket: "Посмотреть билет",
    securePayment: "Безопасная оплата",
    payment_pending: "Оплата обрабатывается",
    payment_failed: "Оплата не удалась",
    payment_cancelled: "Оплата отменена",
    payment_expired: "Срок оплаты истек",  

    cardDetails: "Card Details",
    processingPayment: "Processing payment...",
    authenticating: "Authenticating...",
    paymentSuccess: "Payment successful",  
  }
};