import React, { useState, useEffect } from 'react';
import { 
  useStripe, 
  useElements,
  PaymentElement
} from '@stripe/react-stripe-js';
import axios from 'axios';

const PaymentForm = ({ booking }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!stripe || !elements) {
        throw new Error('Stripe не инициализирован');
      }

      // Получаем сумму из бронирования и конвертируем в минимальные единицы валюты
      const amount = Math.round(parseFloat(booking.price) * 100);
      const currency = booking.currency?.toLowerCase() || 'eur';

      // Создаем метаданные для платежа
      const metadata = {
        bookingId: booking.id,
        routeId: booking.routeId,
        passengerEmail: booking.email,
        departureStation: booking.departureStation,
        arrivalStation: booking.arrivalStation
      };

      // Создаем платежное намерение
      const response = await axios.post('/api/stripe/create-payment-intent', {
        amount,
        currency,
        metadata
      });

      const { clientSecret } = response.data;

      // Подтверждаем платеж
      const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${window.location.origin}/booking/confirmation`,
        },
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      // Платеж успешен
      console.log('Платеж успешно обработан:', paymentIntent);

    } catch (error) {
      console.error('Ошибка платежа:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      setError(
        error.message || 
        'Произошла ошибка при обработке платежа'
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <PaymentElement />
      <button 
        type="submit" 
        disabled={loading || !stripe || !elements}
        className="payment-button"
      >
        {loading ? 'Обработка...' : 'Оплатить'}
      </button>
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
    </form>
  );
};

export default PaymentForm;