import React, { useState } from 'react';

const BookingForm = ({ onBook, route, seat, t }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: new Date().toISOString().split('T')[0]
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Функция валидации телефона
  const validatePhone = (phone) => {
    // Удаляем все нецифровые символы кроме +
    const cleanPhone = phone.replace(/[^\d+]/g, '');
    
    // Проверяем формат +XXXXXXXXXXX (от 10 до 13 цифр после +)
    const phoneRegex = /^\+\d{10,13}$/;
    
    if (!phoneRegex.test(cleanPhone)) {
      return false;
    }
    
    return cleanPhone;
  };

  // Обработчик изменения полей формы
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    if (name === 'phone') {
      // Форматируем телефон при вводе
      const formattedPhone = value.startsWith('+') ? value : `+${value}`;
      setFormData(prev => ({
        ...prev,
        [name]: formattedPhone
      }));
      
      // Очищаем ошибку при изменении
      setErrors(prev => ({
        ...prev,
        phone: null
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));

      // Очищаем ошибку для измененного поля
      setErrors(prev => ({
        ...prev,
        [name]: null
      }));
    }
  };

  // Валидация формы
  const validateForm = () => {
    const newErrors = {};
    
    // Валидация имени
    if (!formData.firstName.trim()) {
      newErrors.firstName = t.firstNameRequired;
    } else if (formData.firstName.length < 2) {
      newErrors.firstName = t.firstNameTooShort;
    }
    
    // Валидация фамилии
    if (!formData.lastName.trim()) {
      newErrors.lastName = t.lastNameRequired;
    } else if (formData.lastName.length < 2) {
      newErrors.lastName = t.lastNameTooShort;
    }
    
    // Валидация email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email) {
      newErrors.email = t.emailRequired;
    } else if (!emailRegex.test(formData.email)) {
      newErrors.email = t.invalidEmail;
    }
    
    // Валидация телефона
    const validPhone = validatePhone(formData.phone);
    if (!formData.phone) {
      newErrors.phone = t.phoneRequired;
    } else if (!validPhone) {
      newErrors.phone = t.invalidPhone;
    }
    
    // Валидация даты рождения
    const birthDate = new Date(formData.dateOfBirth);
    const today = new Date();
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 120); // Максимальный возраст 120 лет
    
    if (!formData.dateOfBirth) {
      newErrors.dateOfBirth = t.dateOfBirthRequired;
    } else if (birthDate >= today) {
      newErrors.dateOfBirth = t.invalidDateOfBirth;
    } else if (birthDate < minDate) {
      newErrors.dateOfBirth = t.dateOfBirthTooOld;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Обработчик отправки формы
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    try {
      const validPhone = validatePhone(formData.phone);
      if (!validPhone) {
        throw new Error(t.invalidPhone);
      }

      // Подготавливаем данные для бронирования
      const bookingData = {
        passengers: [{
          firstName: formData.firstName.trim(),
          lastName: formData.lastName.trim(),
          email: formData.email.trim(),
          phone: validPhone,
          dateOfBirth: formData.dateOfBirth,
          tariff: 'REGULAR'
        }],
        payment: {
          amount: route.price,
          currency: route.currency
        }
      };

      await onBook(bookingData);
    } catch (err) {
      console.error('Form submission error:', err);
      setError(err.message || t.bookingError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-2xl mx-auto">
       <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow-md">
        {/* Заголовок */}
        <h2 className="text-2xl font-bold text-gray-900 mb-6">{t.passengerDetails}</h2>
        
        {/* Информация о маршруте и месте */}
        <div className="bg-blue-50 p-4 rounded-lg mb-6">
          <h3 className="font-medium text-blue-900 mb-2">{t.bookingSummary}</h3>
          <div className="grid grid-cols-2 gap-4 text-sm text-gray-600">
            <div>
              <p className="font-medium text-gray-700">{t.route}:</p>
              <p>{route.departureStationId} → {route.arrivalStationId}</p>
              <p>{new Date(route.departureTime).toLocaleString()}</p>
            </div>
            <div>
              <p className="font-medium text-gray-700">{t.seat}:</p>
              <p>{t.vehicle} {seat.vehicleNumber}, {t.seat} {seat.number}</p>
              <p className="text-blue-600 font-medium">{route.price} {route.currency}</p>
            </div>
          </div>
        </div>

        {/* Персональная информация */}
        <div className="space-y-4">
          {/* Имя и фамилия */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t.firstName} *
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                  errors.firstName ? 'border-red-500' : 'border-gray-300'
                }`}
                required
              />
              {errors.firstName && (
                <p className="mt-1 text-sm text-red-600">{errors.firstName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {t.lastName} *
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
                className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                  errors.lastName ? 'border-red-500' : 'border-gray-300'
                }`}
                required
              />
              {errors.lastName && (
                <p className="mt-1 text-sm text-red-600">{errors.lastName}</p>
              )}
            </div>
          </div>

          {/* Email */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t.email} *
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                errors.email ? 'border-red-500' : 'border-gray-300'
              }`}
              required
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-600">{errors.email}</p>
            )}
          </div>

          {/* Телефон */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t.phone} * ({t.phoneFormat})
            </label>
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="+420XXXXXXXXX"
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                errors.phone ? 'border-red-500' : 'border-gray-300'
              }`}
              required
            />
            {errors.phone && (
              <p className="mt-1 text-sm text-red-600">{errors.phone}</p>
            )}
          </div>

          {/* Дата рождения */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {t.dateOfBirth} *
            </label>
            <input
              type="date"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              max={new Date().toISOString().split('T')[0]}
              className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent ${
                errors.dateOfBirth ? 'border-red-500' : 'border-gray-300'
              }`}
              required
            />
            {errors.dateOfBirth && (
              <p className="mt-1 text-sm text-red-600">{errors.dateOfBirth}</p>
            )}
          </div>
        </div>

        {/* Общая ошибка */}
        {error && (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
            {error}
          </div>
        )}

        {/* Кнопка отправки */}
        <button
          type="submit"
          disabled={loading}
          className={`
            w-full py-3 px-4 rounded-md text-white font-medium
            ${loading
              ? 'bg-blue-400 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600'
            }
            transition-colors duration-200
          `}
        >
          {loading ? t.processing : t.confirmBooking}
        </button>

        {/* Примечание об обязательных полях */}
        <p className="text-sm text-gray-500 text-center">
          * {t.requiredFields}
        </p>
      </form>
    </div>
  );
};

export default BookingForm;