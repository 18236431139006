import axios from 'axios';

class PaymentAPI {
  constructor(regiojetAPI) {
    this.regiojetAPI = regiojetAPI;
    this.CONFIG = regiojetAPI.CONFIG;
  }

  async createPayment(ticketId, token) {
    try {
      if (!ticketId || !token) {
        throw new Error('Ticket ID and token are required');
      }

      const cleanToken = token.trim();
      const authToken = `Bearer ${cleanToken}`;

      // Получаем информацию о билете для определения суммы
      const ticketInfo = await this.regiojetAPI.getTicketDetails(ticketId);
      
      const paymentData = {
        ticketId,
        amount: Math.round(ticketInfo.price * 100), // Конвертируем в центы
        currency: ticketInfo.currency.toLowerCase(),
        payment_method_types: ['card'],
        metadata: {
          ticketId,
          customerEmail: ticketInfo.customerEmail
        }
      };

      const response = await axios({
        method: 'POST',
        url: '/api/stripe/create-payment-intent',
        headers: {
          'Authorization': authToken,
          'Content-Type': 'application/json'
        },
        data: paymentData
      });

      if (!response.data || !response.data.clientSecret) {
        throw new Error('Invalid payment intent response');
      }

      return {
        clientSecret: response.data.clientSecret,
        paymentIntentId: response.data.paymentIntentId
      };

    } catch (error) {
      console.error('Payment creation failed:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });

      throw this.handlePaymentError(error);
    }
  }

  async getPaymentStatus(ticketId, token) {
    try {
      if (!ticketId || !token) {
        throw new Error('Ticket ID and token are required');
      }

      const cleanToken = token.trim();
      const authToken = `Bearer ${cleanToken}`;

      console.log('Checking payment status:', {
        ticketId,
        tokenPreview: `${cleanToken.substring(0, 10)}...`
      });

      const response = await axios({
        method: 'GET',
        url: `https://brn-qa-ybus-privapi.sa.cz/affiliate/tickets/${ticketId}/payment/status`,
        headers: {
          'Authorization': authToken,
          'Accept': 'application/json'
        }
      });

      const status = (response.data?.status || 'PENDING').toUpperCase();

      console.log('Payment status:', {
        ticketId,
        status,
        data: response.data
      });

      return {
        status,
        paymentId: response.data?.paymentId,
        message: response.data?.message || 'Payment status check successful'
      };
    } catch (error) {
      console.error('Payment status check failed:', {
        ticketId,
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });

      throw error;
    }
  }

  async cancelPayment(ticketId, token) {
    try {
      if (!ticketId || !token) {
        throw new Error('Ticket ID and token are required');
      }

      const cleanToken = token.trim();
      const authToken = `Bearer ${cleanToken}`;

      const response = await axios({
        method: 'PUT',
        url: `https://brn-qa-ybus-privapi.sa.cz/affiliate/tickets/${ticketId}/payment/cancel`,
        headers: {
          'Authorization': authToken,
          'Accept': 'application/json'
        }
      });

      return {
        success: true,
        status: 'CANCELLED',
        message: response.data?.message || 'Payment cancelled successfully'
      };
    } catch (error) {
      console.error('Payment cancellation failed:', error);
      throw error;
    }
  }

  // Вспомогательные методы
  handlePaymentError(error) {
    if (error.response) {
      const { status, data } = error.response;
      const message = data?.message || 'Payment operation failed';

      switch (status) {
        case 400: return new Error(`Invalid payment request: ${message}`);
        case 401: return new Error('Payment authorization failed');
        case 403: return new Error('Payment operation forbidden');
        case 404: return new Error('Payment not found');
        case 409: return new Error('Payment already processed');
        case 500: return new Error('Payment service temporarily unavailable');
        default: return new Error(`Payment error: ${message}`);
      }
    }

    if (error.request) {
      return new Error('Payment service is not responding');
    }

    return new Error(error.message || 'Unexpected payment error');
  }

  getStatusMessage(status) {
    const messages = {
      PENDING: 'Payment is being processed',
      PAID: 'Payment completed successfully',
      FAILED: 'Payment failed',
      CANCELLED: 'Payment was cancelled',
      EXPIRED: 'Payment has expired'
    };
    return messages[status] || `Unknown payment status: ${status}`;
  }
}

export default PaymentAPI;